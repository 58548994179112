
.tag_icons_horizontal_line{
    border-bottom: 4px solid #000000;
    width: 50px;
    margin: auto;
    visibility: hidden;
}

.scrollable_tabs{
    width: 80%;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}