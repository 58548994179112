
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    padding:0px;
    margin:0px;
    // font-family: 'Rosario';
    font-family: 'Poppins';
}

.leaflet-container{
    height: 60vh;
    width: 100vw;
}

.leaflet-control-attribution{
    display:none !important;
}
