
.menu{
    margin-top: 10px;
    margin-left: 20px;

    .menuitem{
        min-width: 200px;
    }
}

.drawer{
    min-width: 200px;
}